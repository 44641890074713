/**
 * Application user.
 */
export default class User {
    /**
     * User id.
     */
    public id: string = '';

    /**
     * User name.
     */
    public name: string = '';

    /**
     * User image.
     */
    public image: string = '';

    /**
     * User is a logged into app user.
     */
    public isMe: boolean = false;
}
