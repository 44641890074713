export default class Dom {
  static getScrollDiv () {
    return document.querySelector('.messages-list') as HTMLDivElement
  }

  static scrollToBottom () {
    const scrollDiv = Dom.getScrollDiv()
    scrollDiv.scrollTop = scrollDiv.scrollHeight
    // scrollDiv.dispatchEvent(new CustomEvent('scroll')) // Q: for what it need?
  }

  static isScrollBottom (scrollSaldo: number = 0) {
    const scrollDiv = Dom.getScrollDiv()

    return (scrollDiv.scrollTop + scrollSaldo) >= (scrollDiv.scrollHeight - scrollDiv.offsetHeight)
  }
}
