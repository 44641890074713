import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import store from '@/store'
import { GET_ACCESS_MODEL } from '@/store/root/getters'

import ChatPage from '@/components/views/chat-page/ChatPage.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: ChatPage
  },
  {
    path: '/chat/:id',
    name: 'chat',
    component: ChatPage,
    props: true
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  // const skipAuthPages = ['login', 'forgetPassword', 'setPassword']
  // if (!skipAuthPages.includes(to.name || 'home') && !store.getters[GET_ACCESS_MODEL]) {
  //   next({ name: 'login' })
  // } else {
  next()
  // }
})

export default router
