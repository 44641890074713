import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import moment from 'moment'

import { GET_MESSAGES } from '@/store/root/getters'
import { SET_MESSAGES } from '@/store/root/mutations'

import Message from '../Message/Message.vue'


@Component({
  components: { Message },
})
export default class MessageDisplay extends Vue {
  public readonly $refs: {
    containerMessagesList: HTMLElement,
  };

  public updateScroll = true

  public lastMessage : string|null = null

  public loading = false

  public scrollBottom = {
    messageSent: true,
    messageReceived: false,
  };

  @Prop({ required: true })
  public linkOptions: any;


  @Getter(GET_MESSAGES)
  public messages: any[]

  @Mutation(SET_MESSAGES)
  public setMessages: (messages: any[]) => void

  /**
   * This function compare two messages without looking at the uploaded propertie.
   * This function has been implemented to prevent chat scrolling down after changing the message from 'uploaded = false' to 'uploaded = true'.
   * @param {Object} message1 the first message object
   * @param {Object} message2 the second message object
   * @return {Boolean} true if the messages are equal and false if they are different
   */
  public messageCompare (message1: any, message2: any): boolean {
    /**
     * if one of the messages are null, you can safely compare the messages with '==='
     */
    if (!message2 || !message1) {
      return message1 === message2
    }
    /**
     * compare the immutable properties of a message
     */
    const participant_equal = message1.sender.id === message2.sender.id
    const content_equal = message1.text === message2.text
    const timestamp_equal = moment(message1.createdAt) === moment(message2.createdAt)
    //  message.timestamp = moment(message.timestamp).toISOString() // TODO: remove
    return participant_equal && content_equal && timestamp_equal
  }

  // @ts-ignore
  public updateScrollState ({ target: { scrollTop, clientHeight, scrollHeight } }) {
    this.updateScroll = scrollTop + clientHeight >= scrollHeight

    if (scrollTop < 20) {
      console.log('loadMoreMessages!')
      // TODO: action to load more messages and add it to messages list in begin
      // this.loading = true
      //
      // this.loadMoreMessages((messages: any) => {
      //   // if (Array.isArray(messages) && messages.length > 0) {
      //   /**
      //    * this code will be removed before the next release
      //    *
      //    * this line is commented because the setMessages is already called
      //    * when 'this.messages.unshift(...this.toLoad)' is executed at App.vue line 177
      //    * it was executing the same function twice, causing unexpected behavior with Luxon date objects
      //    */
      //   // this.setMessages([...messages, ...this.messages]);
      //   // }
      //   this.loading = false
      // })
    }
  }

  public goToBottom (): void {
    const scrollDiv: any = this.$refs.containerMessagesList
    scrollDiv.scrollTop = scrollDiv.scrollHeight

    this.updateScroll = false
  }

  public onImageClicked (message: any): void {
    this.$emit('onImageClicked', message)
  }

  public mounted () {
    this.goToBottom()
    this.$refs.containerMessagesList.dispatchEvent(new CustomEvent('scroll'))
  }

  public updated () {
    if (this.messages.length &&
      !this.messageCompare(this.messages[this.messages.length - 1], this.lastMessage)
    ) {
      if (this.updateScroll ||
        ((this.scrollBottom as any).messageSent && this.messages[this.messages.length - 1].my) ||
        ((this.scrollBottom as any).messageReceived && !this.messages[this.messages.length - 1].my)
      ) {
        this.goToBottom()
        if (this.messages.length) {
          this.lastMessage = this.messages[this.messages.length - 1]
        }
      }
    }
  }
}
