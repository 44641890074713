import { Store } from 'vuex'

import ResponseModel from '@/core/models/ResponseModel'
import AccessModel from '@/core/models/AccessModel'
import * as getters from '@/store/root/getters'
import * as mutations from '@/store/root/mutations'


/**
 * Helper class for sending http requests.
 */
export default class ApiRequest {
  /**
   * Vuex root store.
   */
  private store: Store<{}>;
  private apiUrl: string;

  /**
   * Creates Startup instance.
   *
   * @param store   Vuex root store.
   * @param apiUrl  Apu URL.
   */
  public constructor (store: Store<{}>, apiUrl: string) {
    this.store = store
    this.apiUrl = apiUrl
  }

  /**
   * Sends request to API.
   *
   * @param method             Request method.
   * @param endpoint           Api endpoint url.
   * @param body               Json-type request body.
   *
   * @return   Resolved promise with server response.
   */
  public async send (
    method: string,
    endpoint: string,
    body?: string | FormData,
  ): Promise<any> {
    let response: ResponseModel
    return new Promise((resolve, reject) => {
      const accessModel = this.store.getters[`${getters.GET_ACCESS_MODEL}`]
      const isRawForm = body && body instanceof FormData

      const http = new XMLHttpRequest()
      http.open(method, this.apiUrl + endpoint)

      if (!isRawForm) {
        http.setRequestHeader('Content-Type', 'application/json')
      }

      if (!accessModel) {
        this.showAuthError()
        reject(new Error('Auth required'))
      }

      http.setRequestHeader('authorization', 'Bearer ' + accessModel.accessToken)

      http.addEventListener('readystatechange', async () => {
        if (http.readyState === 4) {
          if ([200, 201].includes(http.status)) {
            const headers = http.getAllResponseHeaders()
            try {
              const data = JSON.parse(http.response)
              const headers = http.getAllResponseHeaders()
              return resolve(new ResponseModel(data, headers))
            } catch (error) {
              return reject(error)
            }
          }


          if (http.status === 401) {
            // if auth token is null and again 401 - then refresh token is incorrect too
            if (!accessModel.accessToken) {
              this.store.commit(`${mutations.SET_ACCESS_MODEL}`, null)
              this.showAuthError()
              return reject(new Error(http.response))
            }

            const refreshResult = await this.refreshAccessToken()
            if (!refreshResult) {
              this.showAuthError()
              return reject(new Error(http.response))
            }

            return this.send(method, endpoint, body)
          }


          if (http.response === 'Bad Request') {
            return reject(new Error(http.response))
          }

          try {
            return reject(JSON.parse(http.response))
          } catch (error) {
            return reject(error)
          }
        }
      }, false)
      http.send(body === undefined ? '' : body)
    })
  }

  private async refreshAccessToken (): Promise<any> {
    const accessModel = this.store.getters[`${getters.GET_ACCESS_MODEL}`]
    if (!accessModel || !accessModel.refreshToken) {
      console.log('Cant refresh, not exists refresh token')
      return false
    }

    accessModel.accessToken = null
    this.store.commit(`${mutations.SET_ACCESS_MODEL}`, accessModel)

    const loggedUser = this.store.getters[`${getters.GET_LOGGED_USER}`]
    if (!loggedUser || !loggedUser.phone) {
      console.log('Cant refresh, not exists logged user')
      return false
    }

    return this.send(
      'POST',
      'auth/user/create-token',
      JSON.stringify({
        phone: loggedUser.phone,
        refresh_token: accessModel.refreshToken,
      })
    ).then((response: ResponseModel) => {
      accessModel.accessToken = response.data.access_token
      this.store.commit(`${mutations.SET_ACCESS_MODEL}`, accessModel)
      return true
    })
  }

  private showAuthError (): void {
    this.store.commit(`${mutations.SET_FATAL_ERROR_MESSAGE}`, 'Ошибка авторизации. Обновите страницу.')
  }
}
