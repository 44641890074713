import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation, Action } from 'vuex-class'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { WebSocketLink } from '@apollo/client/link/ws'
import { InMemoryCache, ApolloClient, ApolloLink } from '@apollo/client/core'
import moment from 'moment'

import ApiRequest from '@/core/api/ApiRequest'
import MessagesSubscription from '@/core/api/graphql/subscriptions/Messages'
import AccessModel from '@/core/models/AccessModel'
import Chat from '@/core/models/Chat'
import LoggedUserModel from '@/core/models/LoggedUserModel'
import Dom from '@/utils/Dom'

import { SET_API_REQUEST, SET_ACCESS_MODEL, SET_FATAL_ERROR_MESSAGE } from '@/store/root/mutations'
import { REFRESH_CHAT_MESSAGES, REFRESH_CHATS_LIST, REFRESH_LOGGED_USER } from '@/store/root/actions'
import {
  GET_ACCESS_MODEL,
  IS_LOADING,
  GET_CHATS_LIST_UPDATED_AT,
  GET_CHATS_LIST,
  GET_FATAL_ERROR_MESSAGE,
  GET_LOGGED_USER,
  GET_MESSAGES,
  GET_LAST_NOT_MY_MESSAGE,
} from '@/store/root/getters'

import ChatMessage from '@/core/models/ChatMessage'
import { RefreshChatMessagesPayload } from '@/store/root/types'


const API_URL = 'https://api.drvdev.ru/v1/'
const WS_URL = 'wss://api.drvdev.ru/graphql'
// const API_URL = 'https://api.drivechargers.ru/v1/'
// const WS_URL = 'wss://api.drivechargers.ru/graphql'

@Component
export default class App extends Vue {
  private wsClient: any;
  private subscriptionClient: ApolloClient<any>;

  public windowWidth = 0;
  public windowHeight = 0;

  private FORCE_RECONNECT_INTERVAL = 1 * 60000; // 1 minute

  @Getter(GET_ACCESS_MODEL)
  public getAccessModel: AccessModel | null

  @Getter(GET_CHATS_LIST_UPDATED_AT)
  public getChatsListUpdatedAt: string | null

  @Getter(GET_FATAL_ERROR_MESSAGE)
  public getFatalErrorMessage: string | null

  @Getter(GET_LOGGED_USER)
  public getLoggedUser: LoggedUserModel | null

  @Getter(GET_CHATS_LIST)
  public getChatsList: Chat[]

  @Getter(GET_MESSAGES)
  public getMessages: ChatMessage[]

  @Getter(IS_LOADING)
  public isLoading: boolean

  @Getter(GET_LAST_NOT_MY_MESSAGE)
  public lastNotMyMessage: ChatMessage | null

  @Mutation(SET_API_REQUEST)
  public setApiRequest: (instance: ApiRequest) => void

  @Mutation(SET_ACCESS_MODEL)
  public setAccessModel: (model: AccessModel | null) => void

  @Mutation(SET_FATAL_ERROR_MESSAGE)
  public setFatalErrorMessage: (fatalErrorMessage: string | null) => void

  @Action(REFRESH_CHATS_LIST)
  public refreshChatsList: (from: string) => void

  @Action(REFRESH_LOGGED_USER)
  public refreshLoggedUser: () => void

  @Action(REFRESH_CHAT_MESSAGES)
  public refreshChatMessages: (payload: RefreshChatMessagesPayload) => Promise<void>

  /**
   * Log out from app.
   */
  public logout () {
    this.setAccessModel(null)
    this.$router.push({ name: 'login' })
  }

  /**
   * Ios hack to recalculate sizes.
   */
  public onWindowResize () {
    const body = document.querySelector('body')
    if (body) {
      this.windowWidth = body.offsetWidth
      this.windowHeight = body.offsetHeight
    }
    const header = document.querySelector('.header-container')
    const main = document.querySelector('.container-message-display')
    const footer = document.querySelector('.container-message-manager')
    if (header && main) {
      // this.windowHeight =
      //   (header as any).offsetHeight + (main as any).offsetHeight + 14
    }
  }

  /**
   * Trick to viewport units on mobile devices and tables.
   * Into ios `vh` unit calculated only for initial window state and not
   * changed when address bar shown/hide.
   *
   * More info:
   * {@link https://css-tricks.com/the-trick-to-viewport-units-on-mobile/}
   */
  public updateScaleUnits () {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  public subscribeToUpdates () {
    if (!this.getLoggedUser) {
      return
    }

    if (!this.getAccessModel || !this.getAccessModel.accessToken) {
      return
    }

    if (this.wsClient) {
      this.wsClient.close(true, true)
    }

    // WS https://www.npmjs.com/package/subscriptions-transport-ws
    this.wsClient = new SubscriptionClient(WS_URL, {
      reconnect: true,
      timeout: 10000,
      inactivityTimeout: 10000,
      reconnectionAttempts: 100000000,
      connectionParams: {
        authToken: `Bearer ${this.getAccessModel.accessToken}`
      }
    })

    this.wsClient.onConnected(() => console.log('websocket connected!!'))
    this.wsClient.onDisconnected(() => console.log('websocket disconnected!!'))
    this.wsClient.onReconnected(() => console.log('websocket reconnected!!'))
    this.wsClient.onError((error: any) => console.log('websocket Error!!', error))

    this.subscriptionClient = new ApolloClient({
      link: new WebSocketLink(this.wsClient),
      cache: new InMemoryCache()
    })

    this.subscriptionClient.subscribe({
      query: MessagesSubscription,
      variables: {
        ownerId: this.getLoggedUser.id
      }
    }).subscribe({
      next: (data: any) => {
        const chatId = data.data.messages.chat_id

        if (chatId === 'support') {
          this.refreshChatMessages({ chatId: 'support', from: this.lastNotMyMessage?.createdAt })
        }

        console.log(`Subscribe notification received: '${data.data && data.data.messages && data.data.messages.text}'`) // TODO: remove
      }
    })
  }


  /**
   * Created lifecycle hook. Fires when component instance is created.
   */
  public async created () {
    this.setFatalErrorMessage(null)
    try {
      const search = location.search.substring(1)
      const searchParams = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
      const access_token = searchParams.token || null
      const refresh_token = searchParams.refresh || null
      this.setAccessModel(new AccessModel(access_token, refresh_token))
      this.setApiRequest(new ApiRequest(this.$store, API_URL))

      await this.refreshLoggedUser()
      this.subscribeToUpdates()
      setInterval(() => {
        this.subscribeToUpdates()
      }, this.FORCE_RECONNECT_INTERVAL)
    } catch (error) {
      console.log('Access token not found: ', error)
    }

    const from = this.getChatsListUpdatedAt === null
      ? moment().toISOString()
      : this.getChatsListUpdatedAt
    await this.refreshChatsList(from) // TODO: set loading
    console.log('CREATED, chatsList')
  }

  public mounted () {
    this.onWindowResize()
    window.addEventListener('resize', this.onWindowResize)
    this.updateScaleUnits()
    window.addEventListener('resize', this.updateScaleUnits)
    window.addEventListener('resize', Dom.scrollToBottom)
  }
}
