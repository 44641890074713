import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'

import ImageHelper from '@/core/ImageHelper'
import Dom from '@/utils/Dom'
import LoggedUserModel from '@/core/models/LoggedUserModel'

// @ts-ignore
import SendIcon from 'vue-material-design-icons/ArrowUp'
// @ts-ignore
import PaperclipIcon from 'vue-material-design-icons/Paperclip'
import { Getter, Mutation } from 'vuex-class'

import ChatMessage from '@/core/models/ChatMessage'
import UserModel from '@/core/models/User'

import { NEW_MESSAGE } from '@/store/root/mutations'
import { GET_LOGGED_USER } from '@/store/root/getters'


@Component({
  components: { SendIcon, PaperclipIcon },
})
export default class MessageManager extends Vue {
  public readonly $refs: {
    userInput: HTMLInputElement,
    inputImage: HTMLElement,
  };

  public textInput: string|null = ''

  @Prop({ default: true })
  public sendImages: boolean | null

  @Prop({ required: true })
  public acceptImageTypes: string

  @Mutation(NEW_MESSAGE)
  public newMessage: (message: ChatMessage) => void

  @Getter(GET_LOGGED_USER)
  public loggedUser: LoggedUserModel | null

  public sendMessage (e: Event): void {
    if (!this.loggedUser) {
      return
    }

    this.textInput = this.$refs.userInput.textContent
    this.$refs.userInput.textContent = ''
    // match characters that are different of spaces, tabs, line breaks...
    const matchNotEmpty = /[^\s]+/i
    // match characters that are between line spaces, tabs, line breaks...
    const contentMatch = this.textInput!.match(/^\s*((.|\n)+?)\s*$/i)

    if (this.textInput && matchNotEmpty.test(this.textInput) && contentMatch) {
      const message = this.createMessage()
      message.text = contentMatch[1]

      this.$emit('onMessageSubmit', message)
      this.newMessage(message)

      this.$nextTick(Dom.scrollToBottom)
    }
  }

  public handleType (e: Event): void {
    this.$emit('onType', e)
  }

  public pickImage (): void {
    this.$refs.inputImage.click()
  }

  public async handleImageChange (e: Event) {
    if (!this.loggedUser) {
      return
    }

    const imageInput = (e.target as HTMLInputElement)
    const imageFile = imageInput.files![0]
    const message = this.createMessage()
    const image = await ImageHelper.getImageFromFile(imageFile)
    const resizedImageFile = await this.getResizedImageFile(imageFile, image)

    message.attachments = [{
      image: URL.createObjectURL(resizedImageFile),
      preview: URL.createObjectURL(resizedImageFile),
      height: ImageHelper.calcPhotoHeight(image),
    }]

    this.newMessage(message)
    this.$emit('onImageSelected', message, resizedImageFile)
  }

  private createMessage () {
    const message = new ChatMessage()
    message.attachments = null
    message.createdAt = moment().toISOString()
    message.sender = this.getSender()

    return message
  }

  private getSender () {
    if (!this.loggedUser) {
      return {} as UserModel
    }

    const sender = new UserModel()
    sender.id = this.loggedUser.id
    sender.name = `${this.loggedUser.last_name} ${this.loggedUser.first_name}`
    sender.image = this.loggedUser.avatar_url || ''
    sender.isMe = true

    return sender
  }

  private async getResizedImageFile (imageFile: File, image: HTMLImageElement) {
    const MAX_SIZE = 1280

    if (Math.max(image.width, image.height) < MAX_SIZE) {
      return imageFile
    }

    const canvas = ImageHelper.imageToCanvas(image, MAX_SIZE)
    const resizedImageFile = await ImageHelper.canvasToFile(canvas, imageFile)

    return resizedImageFile
  }
}
