import { ActionContext, ActionTree } from 'vuex'
import moment from 'moment'

import ApiRequest from '@/core/api/ApiRequest'
import AccessModel from '@/core/models/AccessModel'
import ResponseModel from '@/core/models/ResponseModel'
import UserModel from '@/core/models/User'
import Chat from '@/core/models/Chat'
import ChatMessage from '@/core/models/ChatMessage'
import LoggedUserModel from '@/core/models/LoggedUserModel'

import RootState from '@/store/root/state'
import * as mutations from '@/store/root/mutations'
import { RefreshChatMessagesPayload } from '@/store/root/types'


export const LOG_IN_USER: string = 'logInUser'

export const REFRESH_CHATS_LIST: string = 'refreshChatsList'

export const REFRESH_CHAT_MESSAGES: string = 'refreshChatMessages'

export const REFRESH_LOGGED_USER: string = 'refreshLoggedUser'

export const SUBMIT_MESSAGE: string = 'submitMessage'

export const SUBMIT_PHOTO: string = 'submitPhoto'


export function logInUser (
  store: ActionContext<RootState, any>,
  payload: { login: string, password: string },
) {
  const { login, password } = payload
  return store.getters.getApiRequest.send(
    'POST',
    'auth',
    JSON.stringify({ login, password })
  ).then((response: ResponseModel) => {
    const { access_token, refresh_token } = response.data
    const accessModel = new AccessModel(access_token, refresh_token)
    store.commit(mutations.SET_ACCESS_MODEL, accessModel)

    return store.getters.getApiRequest.send(
      'GET',
      'auth',
    )
  }).then((response: ResponseModel) => {
    const user = response.data
    const accessModel = store.getters.getAccessModel
    accessModel.issuedAt = user.iat
    accessModel.expiredAt = user.exp

    accessModel.user = new UserModel()
    accessModel.user.id = user.id
    accessModel.user.username = user.username
    accessModel.user.fullname = user.fullname
    accessModel.user.email = user.email
    accessModel.user.role = user.role
    accessModel.user.created_at = user.created_at

    store.commit(mutations.SET_ACCESS_MODEL, accessModel)
  })
}

export function refreshChatsList (
  store: ActionContext<RootState, any>,
  from: string,
) {
  return store.getters.getApiRequest.send(
    'GET',
    'chats?from=' + encodeURI(from)
  ).then((response: ResponseModel) => {
    const chats = response.data
    store.commit(mutations.SET_CHATS_LIST_UPDATED_AT, moment().toISOString())

    let prevChats = store.state.chatsList
    prevChats = prevChats.map((chat: any) => Object.assign(chat, { isOld: true }))
    const messagesOfChat: any = {}
    for (const chat of prevChats) {
      messagesOfChat[chat.id] = chat.messages
    }

    const allChats = prevChats
    for (const chat of chats) {
      allChats.push({
        id: chat.id,
        name: chat.name,
        image: chat.image,
        text: chat.text,
        status: chat.status,
        position: chat.position,
        updatedAt: chat.updated_at,
        messages: [],
      })
    }

    allChats.sort((a: any, b: any) => {
      if (a.position !== null && b.position === null) { return -1 }
      if (b.position !== null && a.position === null) { return 1 }
      if (a.position === null && b.position === null) {
        if (!a.isOld && b.isOld) { return -1 }
        if (!b.isOld && a.isOld) { return 1 }
        return a.updatedAt > b.updatedAt
          ? -1
          : (a.updatedAt === b.updatedAt ? 0 : 1)
      }

      // Fixed chats(not null positions)
      if (a.position < b.position) { return -1 }
      if (b.position < a.position) { return 1 }
      if (!a.isOld && b.isOld) { return -1 }
      if (!b.isOld && a.isOld) { return 1 }
      return a.updatedAt > b.updatedAt
        ? -1
        : (a.updatedAt === b.updatedAt ? 0 : 1)
      // return -1 if a < b, return 1 if b < a
    })

    const filteredChats: Chat[] = []
    const justAddedChats: any[] = []
    for (const chat of allChats) {
      if (!justAddedChats.includes(chat.id)) {
        justAddedChats.push(chat.id)
        const messages = messagesOfChat[chat.id] || null
        filteredChats.push(Object.assign(chat, { messages }))
      }
    }

    store.commit(mutations.SET_CHATS_LIST, filteredChats)
  })
}

export function refreshChatMessages (
  store: ActionContext<RootState, any>,
  payload: RefreshChatMessagesPayload,
) {
  const { chatId, from = '' } = payload

  return store.getters.getApiRequest.send(
    'GET',
    'chats/' + encodeURI(chatId) + '?from=' + encodeURI(from)
  ).then((response: ResponseModel) => {
    const chatMessages: ChatMessage[] = store.state.messagesList
    const existedIds = chatMessages.map((message: any) => message.id)

    const messages = response.data && response.data.messages ? response.data.messages : []

    const newMessages: ChatMessage[] = []
    for (const message of messages) {
      if (existedIds.includes(message.id)) {
        continue
      }

      const sender = new UserModel()
      sender.id = message.sender.id
      sender.name = message.sender.name
      sender.image = message.sender.image
      sender.isMe = message.sender.id === store.state.loggedUser?.id

      newMessages.push({
        id: message.id,
        title: message.title,
        text: message.text,
        attachments: message.attachments,
        status: message.status,
        createdAt: message.created_at,
        sender,
      })
    }

    if (newMessages.length) {
      store.commit(mutations.ADD_MESSAGES, newMessages)
      store.commit(mutations.SET_UNREAD_MESSAGES, from ? newMessages.length : 0)
    }
  })
}

export function refreshLoggedUser (
  store: ActionContext<RootState, any>,
) {
  return store.getters.getApiRequest.send(
    'GET',
    'auth/user',
  ).then((response: ResponseModel) => {
    store.commit(mutations.SET_LOGGED_USER, new LoggedUserModel(response.data))
  })
}


export function submitMessage (
  store: ActionContext<RootState, any>,
  message: ChatMessage,
) {
  return store.getters.getApiRequest.send(
    'POST',
    'chats/support',
    JSON.stringify({
      text: message.text,
      attachments: message.attachments,
    })
  ).then((response: ResponseModel) => {
    message.id = response.data.id
    message.status = 'sent'
  })
}

export async function submitPhoto (
  store: ActionContext<RootState, any>,
  image: File,
) {
  const formData = new FormData()
  formData.append('file', image)

  return store.getters.getApiRequest.send(
    'POST',
    'chats/support/photos',
    formData
  ).then((response: ResponseModel) => {
    return response.data.url
  })
}

export default {
  logInUser,
  refreshChatsList,
  refreshChatMessages,
  refreshLoggedUser,
  submitMessage,
  submitPhoto,
} as ActionTree<RootState, any>
