import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import Dom from '@/utils/Dom'

// @ts-ignore
import ArrowDown from 'vue-material-design-icons/ArrowDown.vue'

import { Getter, Mutation } from 'vuex-class'
import { HAS_UNREAD_MESSAGES } from '@/store/root/getters'
import { SET_UNREAD_MESSAGES } from '@/store/root/mutations'
import { SetUnreadMessages } from '@/store/root/types'


@Component({
  components: { ArrowDown },
})
export default class MessageManager extends Vue {
  @Getter(HAS_UNREAD_MESSAGES)
  public hasUnreadMessages: boolean

  @Mutation(SET_UNREAD_MESSAGES)
  public setUnreadMessages: SetUnreadMessages

  public showUnreadMessages (e: Event): void {
    Dom.scrollToBottom()
  }

  public onMessagesScroll (e: Event): void {
    if (!this.hasUnreadMessages) {
      return
    }

    if (Dom.isScrollBottom(20)) {
      this.setUnreadMessages(false)
    }
  }

  @Watch('hasUnreadMessages')
  public onUnreadMessagesUpdate (value: boolean) {
    if (!value) {
      return
    }

    this.$nextTick(() => {
      const scrollDiv = Dom.getScrollDiv()
      const diff = scrollDiv.scrollHeight - scrollDiv.offsetHeight - scrollDiv.scrollTop
      if (diff < 120) {
        Dom.scrollToBottom()
        this.setUnreadMessages(false)
      }
    })
  }

  public mounted () {
    Dom.getScrollDiv().addEventListener('scroll', this.onMessagesScroll)
  }
}
