export default class LoggedUserModel {
    public id: string
    public phone: string
    public email: string | null
    public first_name: string | null
    public last_name: string | null
    public avatar_url: string | null

    public constructor ({ id, phone, email, first_name, last_name, avatar_url }: LoggedUserModel) {
      this.id = id
      this.phone = phone
      this.email = email
      this.first_name = first_name
      this.last_name = last_name
      this.avatar_url = avatar_url
    }
}
