/**
 * Describes ResponseModel
 */
export default class ResponseModel {
  /**
   * Response JSON data.
   */
  private _data: any;

  /**
   * Response headers.
   */
  private _headers: {[key: string]: string} = {};

  /**
   * Default constructor. Sets response body and headers values.
   */
  public constructor (response: any, headers: string) {
    this.data = response
    this.setHeaders(headers)
  }

  /**
   * Returns response body.
   */
  public get data (): any {
    return this._data
  }

  /**
   * Sets response body.
   *
   * @param value   Response body.
   */
  public set data (value: any) {
    this._data = value
  }

  /**
   * Returns response headers.
   */
  public get headers () {
    return this._headers
  }

  /**
   * Sets response headers like key-value pairs.
   *
   * @param value   Response headers.
   */
  public setHeaders (value: string) {
    const responseHeaders = value.split('\n')
    for (const header of responseHeaders) {
      const name = header.split(': ')[0].toLowerCase()
      const headerValue = header.split(': ')[1]
      if (headerValue !== undefined) {
        this._headers[name] = headerValue.trim()
      }
    }
  }
}
