import Vue from 'vue'
import App from '@/components/app/App.vue'
import router from './router'
import store from './store'
import moment from 'moment'


Vue.config.productionTip = false

// moment.locale('ru')
// moment.tz.setDefault('Europe/Moscow')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
