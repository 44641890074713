import Vue from 'vue'
import Vuex, { ActionTree, GetterTree, MutationTree, Store } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import actions from '@/store/root/actions'
import getters from '@/store/root/getters'
import mutations from '@/store/root/mutations'
import RootState from '@/store/root/state'


Vue.use(Vuex)

const plugins: any[] = []
plugins.push(createPersistedState({
  paths: [
    'accessModel',
  ],
}))

/**
 * Vuex store instance, initialized with required root store,
 * modules and plugins.
 */
export const store: Store<any> = new Store({
  actions,
  getters,
  mutations,
  plugins,
  state: new RootState(),
})

export default store
