import User from '@/core/models/User'

/**
 * Chat message.
 */
export default class ChatMessage {
    /**
     * Message id.
     */
    public id: string = '';

    /**
     * Sender id.
     */
    public sender: User;

    /**
     * Message title.
     */
    public title: string | null = null;

    /**
     * Message text.
     */
    public text: string | null = null;

    /**
     * Attachments.
     */
    public attachments: [{
        image: string,
        height: number,
        preview: string,
    }] | null = null;

    /**
     * Message status.
     */
    public status: 'new' | 'viewed' | 'sent' = 'new';

    /**
     * Message created date.
     */
    public createdAt: any;
}
