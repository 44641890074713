export default class ImageHelper {
  public static getImageFromFile (file: File): Promise<HTMLImageElement> {
    return new Promise((resolve) => {
      const reader = new FileReader()

      reader.onloadend = function () {
        const image = new Image()
        image.src = reader.result as string

        image.onload = function () {
          resolve(image)
        }
      }

      reader.readAsDataURL(file)
    })
  }

  public static imageToCanvas (image: HTMLImageElement, maxSize: number) {
    let imageWidth = image.width
    let imageHeight = image.height

    if (imageWidth > imageHeight) {
      if (imageWidth > maxSize) {
        imageHeight *= maxSize / imageWidth
        imageWidth = maxSize
      }
    } else {
      if (imageHeight > maxSize) {
        imageWidth *= maxSize / imageHeight
        imageHeight = maxSize
      }
    }

    const canvas = document.createElement('canvas')
    canvas.width = imageWidth
    canvas.height = imageHeight

    const ctx = canvas.getContext('2d')
    ctx!.drawImage(image, 0, 0, imageWidth, imageHeight)

    return canvas
  }

  public static canvasToFile (canvas: HTMLCanvasElement, options: { name: string, type: string}): Promise<File> {
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(new File([blob!], options.name, options))
      }, options.type)
    })
  }

  public static calcPhotoHeight (photo: HTMLImageElement | HTMLCanvasElement) {
    const MAX_WIDTH = 275
    const MAX_HEIGHT = 390
    const KOEF_MAX_HOR_ORIENTATION = MAX_WIDTH / MAX_HEIGHT
    const koef = photo.width / photo.height
    const isHorizontalImage = koef > KOEF_MAX_HOR_ORIENTATION

    if (isHorizontalImage) {
      const width = photo.width > MAX_WIDTH ? MAX_WIDTH : photo.width

      return Math.round(width / koef)
    }

    return Math.round(photo.height > MAX_HEIGHT ? MAX_HEIGHT : photo.height)
  };
}
