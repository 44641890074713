import { gql } from '@apollo/client/core'


export default gql`
      subscription messages($ownerId: String) {
        messages(owner: $ownerId){
          id
          chat_id
          sender_id
          sender {
            id
            name
            image
          }
          receiver_id
          title
          text
          attachments {
            image
            height
            preview
          }
          timestamp
          status
        }
      }
    `
