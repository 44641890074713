import UserModel from '@/core/models/User'


/**
 * Application access data.
 */
export default class AccessModel {
    /**
     * Time before token expire date when it must be refreshed.
     */
    public readonly refreshBeforeTime = 300

    /**
     * Current user`s data.
     */
    public user: UserModel | null = null

    /**
     * Access token, received from api.
     */
    public accessToken: string

    /**
     * Refresh token, received from api.
     */
    public refreshToken: string

    /**
     * Date when access token will be expired, in ISO string format.
     */
    public expiredAt: string

    /**
     * Date when access token was issued, in ISO string format.
     */
    public issuedAt: string

    /**
     * Constructor. Just sets access token value and refresh token value from
     * constructor parameters.
     *
     * @param accessToken   Access token value.
     * @param refreshToken  Refresh token value.
     */
    public constructor (accessToken: string, refreshToken: string) {
      this.accessToken = accessToken
      this.refreshToken = refreshToken
    }
}
